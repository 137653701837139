import { fromJS, Map } from 'immutable';
import flamelink from 'flamelink';
import firebase from './firebase';

const fl = flamelink({ firebaseApp: firebase });

const DEFAULT_CONTENT = new Map();

// Actions
const ACTIONS = {
  UPDATE_CONTENT: 'Content:Update',
};

export const updateContent = (content) => ({
  type: ACTIONS.UPDATE_CONTENT,
  content,
});

// Reducer
export const ContentReducer = (state = DEFAULT_CONTENT, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_CONTENT:
      return state.mergeDeep(fromJS(action.content));
    default:
      return state;
  }
};

// Selectors
const getPages = (state) => state.get('pages');
const getPageByTitle = (state, title) => {
  const pages = getPages(state);
  if (!pages) return undefined;
  return pages.filter((p) => p.get('title') === title).first();
};

const getContentSections = (state) => state.getIn(['contentSection', 'en-US']);
const getContentSectionBySlug = (state, slug) => {
  const contentSections = getContentSections(state);
  if (!contentSections) return undefined;

  return contentSections.find((p) => p.get('slug') === slug);
};

const getSnippets = (state) => state.get('snippets');
const getSnippetByTitle = (state, title) => {
  const snippets = getSnippets(state);
  if (!snippets) return;
  // eslint-disable-next-line consistent-return
  return snippets.filter((p) => p.get('title') === title).first();
};

export const ContentSelectors = {
  getPages,
  getPageByTitle,
  getSnippets,
  getSnippetByTitle,
  getContentSections,
  getContentSectionBySlug,
};

// eslint-disable-next-line no-console
const logFlamelinkError = (error) => console.log('flamelink error: ', error);

// Init
const Flamelink = {
  init(store) {
    try {
      fl.content
        .get('contentSection')
        .then((contentSections) => store.dispatch(updateContent({ contentSections })))
        .catch(logFlamelinkError);

      fl.content
        .get('pages')
        .then((pages) => store.dispatch(updateContent({ pages })))
        .catch(logFlamelinkError);

      fl.content
        .get('snippets')
        .then((snippets) => store.dispatch(updateContent({ snippets })))
        .catch(logFlamelinkError);
    } catch (error) {
      logFlamelinkError(error);
    }
  },
};

export default Flamelink;
