import * as api from '../../api';
import { genericAPIActionTypes, genericAPIActionCreator } from '../../util';

const ACTIONS = {
  FETCH_FUNDS: genericAPIActionTypes('Fund:FetchFunds'),
  SET_FILTER: 'FundFilter:Set',
};

export const fetchFunds = (params) => genericAPIActionCreator(() => api.listFunds(params), ACTIONS.FETCH_FUNDS, 'Failed to list funds');
export const setFilter = (keywords) => ({ type: ACTIONS.SET_FILTER, keywords });

export default ACTIONS;
