import { connect } from 'react-redux';
import FundSearchView from './fund_search_view';
import { getFilter } from '../../../reducer';
import { setFilter } from '../../actions';

const mapStateToProps = (state) => ({
  filter: getFilter(state),
});

const mapDispatchToProps = {
  setFilter,
};

const FundSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FundSearchView);

export default FundSearchContainer;
