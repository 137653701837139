import styled from 'styled-components';
import theme from '../../../common/theme';
import device from '../../../common/device';

const DirectoryStyles = styled.div`
  overflow: hidden;

  .ReactTable {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 6px ${theme.color.gray.dark};
    padding-bottom: 1rem;

    * {
      box-sizing: border-box
    }

    .rt-table {
      .rt-thead {
        border-top: 8px solid ${theme.color.black};
        padding: 1rem 0;

        .rt-tr {
          display: flex;
          flex-direction: row;
          padding:  0.25rem 1rem;
          margin-bottom: 0.5rem;
          color: ${theme.color.gray.dark};
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;

          &:after {
            vertical-align: center;
            content: 'Connect ' ;
            font-size: 12px;
            color: ${theme.color.gray.dark};
            text-transform: uppercase;
            margin: 0 1rem;
            text-align: right;
            opacity: 0;
          }
        }

        .rt-th {
          position: relative;
          cursor: pointer;
          margin-right: 0.5rem;

          &:after {
            position: absolute;
            top: 0;
            left: -1rem;
          }

          &:focus {
            outline: none;
          }

          &.-sort-asc {
            &:after {
              content: url('/assets/icon-arrow-down.svg');

            }
          }

          &.-sort-desc {
            &:after {
              content: url('/assets/icon-arrow-up.svg');
            }
          }
        }
      }

      .rt-tbody {
        margin: 0 0 3em 0;
        min-height: 100px;
        padding: 0;

        .rt-tr {
          border: 1px solid ${theme.color.gray.light};
          box-shadow: inset 6px 0 0 -1px ${theme.color.black};
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding:  0.25rem 1rem;
          position: relative;
          margin-bottom: 0.5rem;
          cursor: pointer;

          &:hover {
            background-color: ${theme.color.gray.lighter};
          }

          &.-padRow {
            display: none;
          }

          &:before {
            position: absolute;
            content: url('/assets/icon-arrow-active.svg');
            bottom: 0.15rem;
            right: 0.5rem;
          }

          &:after {
            vertical-align: center;
            content: 'Connect ' ;
            font-size: 12px;
            color: ${theme.color.gray.dark};
            text-transform: uppercase;
            margin: 0 1rem;
            text-align: right;
          }
        }

        .rt-td {
          margin: 0 0.5rem 0 0;

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }

          &:nth-of-type(5n - 4) {
            color: ${theme.color.black};
            font-weight: bold;
          }

          @media ${device.laptopUp} {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      @media ${device.laptopM} {
        font-size: 16px;

        .rt-thead {

          .rt-th {
            &:nth-of-type(5n - 4) {
              display: none;
            }
          }
        }

        .rt-td {
          background-color: transparent;
          border: 0;
          color: ${theme.color.gray.darker};

          &:before {
            color: ${theme.color.black};
            display: block;
            float: left;
            font-style: italic;
            font-weight: bold;
            margin: 0 1em 0 0;
          }

          &:nth-of-type(5n - 4) {
            width: 100% !important;
          }
        }
      }

      @media ${device.laptop} {
        font-size: 14px;

        .rt-thead {
          display: none;
        }

        .rt-tbody {
          width: 100% !important;
          min-width: initial !important;

          .rt-thead {
            display: none;
          }

          .rt-tr-group {
            width: 100%;

            &:nth-child(even) {
              .rt-tr {
                background: ${theme.color.gray.lighter};
              }
            }
          }

          .rt-tr {
            flex-direction: column;
            flex-wrap: nowrap;
            padding: 1rem 0.5rem;
            margin-bottom: 0.5em;

            &:before {
              top: initial;
              bottom: 0.85rem;
            }

            &:after {
              margin-right: 1.5rem;
            }
          }

          .rt-td {
            background-color: transparent;
            border: 0;
            color: ${theme.color.gray.darker};
            margin: 0;
            padding: 0 1em;
            width: 100% !important;

            &:before {
              color: ${theme.color.black};
              display: block;
              float: left;
              font-style: italic;
              font-weight: bold;
              margin: 0 1em 0 0;
            }

            &:nth-of-type(5n - 4) {
              padding-top: 0.5em;
              padding-bottom: 0.5em;
              width: 100%;
            }

            &:nth-of-type(5n - 3) {
              width: 100%;

              &:before {
                content: 'Manager: ';
              }
            }

            &:nth-of-type(5n - 2) {
              width: 100%;

              &:before {
                content: 'Size: ';
              }
            }

            &:nth-of-type(5n - 1) {
              width: 100%;

              &:before {
                content: 'Geo Focus: ';
              }
            }

            &:nth-of-type(5n) {
              padding-bottom: 0.5em;
              width: 100%;

              &:before {
                content: 'Investment Focus: ';
              }
            }
          }

        }
      }
    }

    .pagination-bottom {
      width: 100%;
    }

    .-pagination {
      align-items: center;
      color: ${theme.color.gray.darker};
      display: flex;
      flex-direction: row;
      font-family: ${theme.font.sans};
      font-size: 14px;
      justify-content: flex-end;
      width: 100%;

      .-btn {
        background: transparent;
        border: 0;
        color: ${theme.color.gray.dark};
        font-family: ${theme.font.sans};
        font-size: 14px;
      }

      .-previous {
        &:before {
          content: url('/assets/icon-nav-left.svg');
        }
      }

      .-next {
        &:before {
          content: url('/assets/icon-nav-right.svg');
        }
      }

      .-previous,
      .-next {
        height: 30px;
        position: relative;
        width: 30px;

        &:before {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          text-align: center;
        }

        .-btn {
          opacity: 0;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 30px;
          padding: 0;
          cursor: pointer;

          &:disabled {
            opacity: 0.65;
            color: white;
            cursor: default;
            overflow: hidden;
            background-color: white;
          }
        }
      }

      .-center {
        display: flex;
        flex-direction: row;
        align-items: center;

        div, span {
          display: inline-block;
        }
      }

      .-pageJump {
        input {
          height: 30px;
          padding: 4px;
          text-align: center;
          width: 40px;

          &[type=number]::-webkit-inner-spin-button,
          &[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }

      .select-wrap {
        display: block;
        margin: 0 0.5rem;
        select {
          display: block;
        }
      }
    }

    .rt-noData {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      background: rgba(255,255,255,0.8);
      transition: all .3s ease;
      z-index: 1;
      pointer-events: none;
      padding: 20px;
      color: rgba(0,0,0,0.5);
    }

    .-loading {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255,255,255,0.8);
      transition: all .3s ease;
      z-index: -1;
      opacity: 0;
      pointer-events: none;

      > div {
        position: absolute;
        display: block;
        text-align: center;
        width: 100%;
        top: 50%;
        left: 0;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.6);
        transform: translateY(-52%);
        transition: all .3s cubic-bezier(.25, .46, .45, .94)
      }

      &.-active {
        opacity: 1;
        z-index: 2;
        pointer-events: all;

        > div {
          transform: translateY(50%)
        }
      }
    }
  }
`;

export const Hero = styled.div`
  .input-group {
    width: 450px;
    background-color: #fff;
    border: 0 solid transparent;
    border-radius: 100px;
    padding: 5px;
    display: table;

    .input,
    .input-addon {
      display: table-cell;
    }

    .input {
      position: relative;
      top: -3px;
      height: 20px;
      width: 425px;
      background: transparent;
      font-size: 18px;
      border: none;
      padding-left: 20px;

      &:focus {
        outline-width: 0;
      }

      &::placeholder {
        color: #bbbbbb;
      }
    }

    .input-addon {
      border-left: none;
      border-right: none;
      border-radius: 100px;
      background-color: #f7981c;
      font-size: 25px;
      padding: 0.15em 1em;
      width: 25px;

      &--start {
        border-left: none;
      }
      &--end {
        border-right: none;
      }
    }

    .input-addon + .input {
      border: none;
    }
  }
`;

export const Content = styled.div`
  max-width: 48rem;
  margin: 0 auto;
  width: 100%;

  h3,
  p {
    text-align: center;
  }

  h3 {
    font-size: 32px;
    padding-top: 45px;
    padding-bottom: 10px;
  }

  p {
    margin-bottom: 45px;
  }

  img {
    &:first-of-type {
      width: 572px;
      height: 70px;
    }

    &:last-of-type {
      width: 570px;
      height: 91px;
    }
  }

  h1,
  h2,
  h3,
  p,
  ul,
  ol {
    margin-top: 20px;
  }

  ol,
  ul {
    padding-left: 40px;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
    ul {
      list-style: circle;
    }
  }

  hr {
    background: none;
    border-bottom: solid 1px #eee;
    border: none;
    margin: 40px 0;
  }
`;

export const Card = styled.div`
  border-bottom: solid 1px #b8c0c8;
  display: flex;
  flex-direction: row;
  min-height: 350px;
`;

export const CardItem = styled.div`
  border-right: solid 1px #b8c0c8;
  padding: 80px;
  width: 50%;

  p {
    color: #6b7c93;
    font-size: 13px;
    padding-bottom: 10px;
    padding-top: 10px;
    word-wrap: break-word;
  }

  a {
    color: #f7981c;
    font-size: 13px;
    text-decoration: none;
  }
`;

export const Discover = styled.div`
  text-align: center;
  color: #6b7c93;

  h2 {
    font-size: 2em;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 1em;
    margin-bottom: 15px;
  }
`;

export const Selectable = styled.div`
  display: flex;
  flex-direction: row;
  height: 225px;
  margin-top: 65px;
`;

export const SelectableItem = styled.a`
  background-color: #354860;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 30px;
  text-align: center;
  width: 33%;

  h1 {
    color: #ffde00;
    font-size: 26px;
    font-weight: 200;
    text-transform: uppercase;
  }

  p {
    color: #d8d8d8;
  }
`;

export const Section = styled.div``;

export const MapContent = styled.div`
  text-align: center;

  h1 {
    font-size: 26px;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    color: #354860;
    margin-bottom: 90px;
  }

  iframe {
    margin-bottom: 45px;
  }
`;

export const Divider = styled.div`
  position: relative;
  text-align: center;
  height: 310px;

  &::after {
    content: '';
    background: url(/assets/main_map_graphic.png) center center no-repeat;
    background-position: 0 0;
    background-color: #354860;
    background-size: cover;
    /* opacity: 0.5; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  img {
    margin: 0 auto;
    position: relative;
    top: 100px;
    display: block;
  }
`;

export const TableContent = styled.div`
  padding: 45px;
  margin: 0 auto;
  width: 100%;

  @media ${device.tablet} {
    padding: 1rem;
  }
`;

export const DarkContent = styled(Content)`
  max-width: 100%;
  background-color: #f6f6f6;
  margin: 0 auto;
  padding-top: 75px;
  padding-bottom: 75px;
  border: solid 1px #b8c0c8;

  h1 {
    margin-top: 0;
  }

  p {
    color: #6b7c93;
  }

  h1,
  p {
    text-align: center;
  }

  .Collapsible__contentInner {
    padding: 10px;
    border: 1px solid $lightGrey;
    border-top: 0;

    p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .Collapsible {
    margin: 0 auto;
    padding: 15px 45px;
    width: 75%;

    h3 {
      font-weight: 200;
      margin-top: 0;
      text-transform: capitalize;
      cursor: pointer;
      &:hover {
        color: #6b7c93;
      }
    }

    h3,
    p {
      text-align: left;
    }
  }

  .Collapsible__trigger {
    background-color: #fff;
    border: 1px solid white;
    color: #000;
    color: $grey;
    display: block;
    font-weight: 200;
    padding: 10px;
    position: relative;
    text-decoration: none;

    &:after {
      background-color: #000;
      color: #fff;
      content: 'Q';
      display: block;
      left: -44px;
      padding: 7px 15px 7px 15px;
      position: absolute;
      top: 0;
      transition: transform 500ms;
    }

    &.is-open {
      &:after {
        background-color: #fff;
        color: #000;
        border: solid 1px #f7981c;
        content: 'A';
        display: block;
        left: -44px;
        padding: 7px 15px 7px 15px;
        position: absolute;
        top: 0;
        transition: transform 500ms;
        transform: rotateZ(360deg);
      }
    }

    &.is-disabled {
      opacity: 0.5;
      background-color: grey;
    }
  }

  .Collapsible__contentOuter {
    background-color: #fff;
  }
`;

export const ResourceLink = styled.div`
  background-color: #fff;
  border-left: solid 8px #000;
  height: 95px;
  margin-bottom: 15px;
  margin: 0 auto;
  padding-left: 45px;
  position: relative;
  width: 900px;

  h3 {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    padding-top: 15px;
    text-transform: uppercase;
  }

  p {
    color: #6b7c93;
    font-size: 14px;
    left: 25px;
    margin-top: 0;
    position: relative;
    text-align: left;
  }

  a {
    bottom: 25px;
    color: #f7981c;
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    right: 45px;
    text-decoration: none;
  }
`;

export const ResourceLinkHorizontal = styled(ResourceLink)`
  border-left: none;
  border-top: solid 8px #000;
  margin-bottom: 45px;
  margin-left: 0;
  min-height: 100px;
  padding-bottom: 20px;
  padding-left: 0;
  width: 100%;

  p {
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
    min-width: 350px;

    span {
      color: #6b7c93;
      display: block;
      margin-top: 10px;
      text-transform: capitalize;
    }
  }

  a {
    font-size: 12px;
    left: 0;
    position: relative;
    top: 8px;
  }
`;

export const Footer = styled.div`
  color: #999;
  font-size: 0.8em;
  margin-top: 100px;
`;

export default DirectoryStyles;
