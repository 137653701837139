import styled from 'styled-components';

const FundStyles = styled.div`
  overflow: hidden;
`;

export const Hero = styled.div`
  padding: 80px 0;
  color: #fff;
  text-align: center;
  position: relative;
  &::after {
    content: '';
    background: url(/assets/main_map_graphic.png) center center no-repeat;
    background-position: 0 0;
    background-color: #354860;
    background-size: cover;
    /* opacity: 0.5; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
  div {
    width: 600px;
    h1 {
      font-family: 'Helvetica';
      font-weight: 100;
      font-size: 3em;
      color: #ffde00;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
      span {
        display: block;
        color: #fff;
      }
    }
    h2 {
      margin: 30px auto;
      width: 500px;
      font-size: 14px;
      font-weight: normal;
    }

    .input-group {
      width: 450px;
      background-color: #fff;
      border: 0 solid transparent;
      border-radius: 100px;
      padding: 5px;
      display: table;

      .input,
      .input-addon {
        display: table-cell;
      }

      .input {
        position: relative;
        top: -3px;
        height: 20px;
        width: 425px;
        background: transparent;
        font-size: 18px;
        border: none;
        padding-left: 20px;

        &:focus {
          outline-width: 0;
        }

        &::placeholder {
          color: #bbbbbb;
        }
      }

      .input-addon {
        border-left: none;
        border-right: none;
        border-radius: 100px;
        background-color: #f7981c;
        font-size: 25px;
        padding: 0.15em 1em;
        width: 25px;

        &--start {
          border-left: none;
        }
        &--end {
          border-right: none;
        }
      }

      .input-addon + .input {
        border: none;
      }
    }
  }
`;

export const Logo = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  padding: 10px 40px;
  background: #000;
  color: #fff;
  font-size: 1.5em;
  z-index: 2;
  a {
    text-decoration: none;
    color: #fff;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 960px;
  padding-bottom: 100px;

  h3,
  p {
    margin: 0 auto;
  }

  h3 {
    font-size: 28px;
    margin-bottom: 75px;
    margin-top: 75px;
  }

  p {
    color: #354860;
    font-size: 16px;
    margin-bottom: 45px;
    margin-top: 45px;
  }
`;

export const Card = styled.div`
  width: 50%;
  margin-right: 15px;
`;

export const Section = styled.div`
  background-color: #f6f6f6;
`;

export const ResourceLink = styled.div`
  background-color: #fff;
  border-left: solid 8px #000;
  margin-bottom: 15px;
  margin: 0 auto;
  padding-left: 45px;
  position: relative;

  h3 {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    padding-top: 15px;
    text-transform: uppercase;
  }

  p {
    color: #6b7c93;
    font-size: 14px;
    margin-top: 0;
    position: relative;
    text-align: left;
  }

  a {
    bottom: 25px;
    color: #f7981c;
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    right: 45px;
    text-decoration: none;
  }
`;

export const ResourceLinkHorizontal = styled(ResourceLink)`
  background-color: #fff;
  border-left: none;
  border-top: solid 8px #000;
  margin-bottom: 25px;
  margin-left: 0;
  min-height: 0;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-top: 10px;

  display: flex;
  flex-direction: row;

  img {
    width: 20%;
  }

  div {
    width: 80%;
    padding-left: 20px;
  }

  h3,
  img {
    display: inline;
  }

  img {
    height: 55px;
    width: 55px;
  }

  p {
    color: #000;
    font-weight: 500;
    text-transform: capitalize;
    min-width: 350px;
    color: #6b7c93;

    span {
      color: #6b7c93;
      display: block;
      margin-top: 10px;
      text-transform: capitalize;
    }
  }

  a {
    font-size: 12px;
    left: 0;
    position: relative;
    top: 8px;
  }

  .url {
    color: #f7981c;
  }
`;

export const MapContainer = styled.div`
  min-height: 250px;
  border-radius: 10px;
  background-color: #6b7c93;
  margin-bottom: 15px;
`;

export const Footer = styled.div`
  color: #999;
  font-size: 0.8em;
  margin-top: 100px;
`;

export default FundStyles;
