import ACTIONS from '../actions';

const DEFAULT_FILTER = '';

const FilterReducer = (state = DEFAULT_FILTER, action) => {
  switch (action.type) {
    case ACTIONS.SET_FILTER:
      return action.keywords;
    default:
      return state;
  }
};
export default FilterReducer;

export const getFilter = (state) => state;
