import React from 'react';
import PropTypes from 'prop-types';
import FundSearchStyles from './fund_search_styles';

const propTypes = {
  filter: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
};

const defaultProps = {
  filter: '',
};

const FundSearchView = ({ filter, setFilter }) => (
  <FundSearchStyles>

    <input type="text" name="fund-search" value={filter} onChange={(e) => setFilter(e.target.value)} placeholder='Search funds in our Directory' />
    <label className="button">
      <input type="submit" for="fund-search" value=""/>
    </label>

  </FundSearchStyles>
);

FundSearchView.propTypes = propTypes;
FundSearchView.defaultProps = defaultProps;

export default FundSearchView;
