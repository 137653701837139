import React from 'react';
import PropTypes from 'prop-types';
import ScrollableAnchor from 'react-scrollable-anchor';
import ScrollableAnchorManager from 'react-scrollable-anchor/lib/Manager';
import { Link } from 'react-router-dom';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import Collapsible from 'react-collapsible';
import HomeStyles, {
  Content,
  DarkContent,
  Card,
  CardItem,
  Selectable,
  SelectableItem,
  Discover,
  DiscoverInner,
  MapContent,
  Divider,
  ResourceLink,
  ResourceLinkHorizontal,
} from './home_styles';
import Logo from '../header/logo';
import Hero from '../hero';
import { Text, TextHeading, TextSubheading } from '../../../common/components/text';
import { db } from '../../../vendor/firebase';
import theme from '../../../common/theme';

const propTypes = {
  page: PropTypes.shape({}),
  faqs: PropTypes.shape({}),
  links: PropTypes.shape([]),
  updateContent: PropTypes.func.isRequired,
  getContentSectionBySlug: PropTypes.func.isRequired,
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
};

const defaultProps = {
  page: undefined,
  faqs: undefined,
  links: undefined,
};

// const Searchbar = () => (
//   <div className="input-group">
//     <input type="text" className="input input--text" placeholder="Search funds in our Directory" />
//     <div className="input-addon input-addon--end">&rarr;</div>
//   </div>
// );

const Cards = ({ getContentSectionBySlug }) => {
  const reinvestContent = getContentSectionBySlug('reinvest');
  const smartMoneyContent = getContentSectionBySlug('smart-money');
  return (
    <Card>
      <CardItem>
        <img src="./assets/reinvest-icon.svg" alt="Reinvest Icon" height="50" width="50" />
        <TextSubheading
          color="dark"
          dangerouslySetInnerHTML={{ __html: reinvestContent && reinvestContent.get('title') }}
        />
        <TextHeading
          dangerouslySetInnerHTML={{ __html: reinvestContent && reinvestContent.get('subTitle') }}
          size="large"
        />
        <Text
          dangerouslySetInnerHTML={{ __html: reinvestContent && reinvestContent.get('content') }}
        />
        <Link to="faqs">Learn More &rarr;</Link>
      </CardItem>

      <CardItem>
        <img src="./assets/smart-money-icon.svg" alt="Smart Money Icon" height="50" width="50" />
        <TextSubheading
          dangerouslySetInnerHTML={{ __html: smartMoneyContent && smartMoneyContent.get('title') }}
        />
        <TextHeading
          dangerouslySetInnerHTML={{ __html: smartMoneyContent && smartMoneyContent.get('subTitle') }}
          size="large"
        />
        <Text
          dangerouslySetInnerHTML={{ __html: smartMoneyContent && smartMoneyContent.get('content') }}
        />
        <Link to="faqs">Learn More &rarr;</Link>
      </CardItem>
    </Card>
  );
};

Cards.propTypes = {
  getContentSectionBySlug: PropTypes.func,
};

Cards.defaultProps = {
  getContentSectionBySlug: undefined,
};

const DiscoverSection = ({ getContentSectionBySlug }) => {
  const discoverContent = getContentSectionBySlug('discover');
  return (
    <Discover>
      <DiscoverInner>
        <TextHeading
          align="center"
          dangerouslySetInnerHTML={{ __html: discoverContent && discoverContent.get('title') }}
          size="large"
        />
        <Text
          dangerouslySetInnerHTML={{ __html: discoverContent && discoverContent.get('content') }}
        />
      </DiscoverInner>
      <Selectable>
        <SelectableItem>
          <Link to="/funds">
            <TextSubheading
              color={theme.color.secondary.default}
              size="large"
              weight="light"
            >
              Funds Directory
            </TextSubheading>
          </Link>
        </SelectableItem>

        <SelectableItem>
          <Link to="/resources">
            <TextSubheading
              color={theme.color.secondary.default}
              size="large"
              weight="light"
            >
              Government Resources
            </TextSubheading>
          </Link>
        </SelectableItem>

        <SelectableItem>
          <Link to="/faqs">
            <TextSubheading
              color={theme.color.secondary.default}
              size="large"
              weight="light"
            >
              Browse FAQ&#39;s
            </TextSubheading>
          </Link>
        </SelectableItem>
      </Selectable>
    </Discover>
  );
};

DiscoverSection.propTypes = {
  getContentSectionBySlug: PropTypes.func,
};

DiscoverSection.defaultProps = {
  getContentSectionBySlug: undefined,
};

const MapSection = () => (
  <MapContent>

    <TextHeading>Where are the opportunity Zones?</TextHeading>
    <p>Source: Economic Innovation Group</p>

    <iframe
      title="Map"
      src="https://esrimedia.maps.arcgis.com/apps/View/index.html?appid=77f3cad12b6c4bffb816332544f04542&amp;level=3"
      width="100%"
      height="665px"
      frameBorder="0"
      scrollinyg="no"
    />

  </MapContent>
);

function scrollToSection(path) {
  const sectionName = path.split('/')[1];
  if (sectionName === undefined) {
    return;
  }
  ScrollableAnchorManager.goToSection(sectionName);
}

// This needs refactoring, should not be considered best practice...
function manualForcedFlamelinkRefresh(updateContent) {
  try {
    db()
      .ref('flamelink/environments/production/content')
      .on('value', (snapshot) => {
        updateContent(snapshot.val());
      });
  } catch (e) {
    // console.error('flamelink ERROR',e);
  }
}

class HomeView extends React.Component {
  componentDidMount() {
    const {
      updateContent,
      match: { path },
    } = this.props;
    manualForcedFlamelinkRefresh(updateContent);
    scrollToSection(path);
  }

  componentDidUpdate({ match: { path: oldPath } }) {
    const {
      match: { path },
    } = this.props;
    if (oldPath !== path) {
      scrollToSection(path);
    }
  }

  render() {
    const { page, faqs, links, getContentSectionBySlug } = this.props;

    const resources = getContentSectionBySlug('resources');

    return (
      <HomeStyles>
        <Logo>
          <Link to="/">
            <b>OZ</b>
            FYI
          </Link>
        </Logo>
        <Hero page="home">
          <Content>
            <div className="HeroInner">
              {/* Replace these with actual text when typekit is available */}
              {/* <img src="./assets/opportunity_text.svg" alt="Opportunity text" />
              <img src="./assets/ZONES_text.svg" alt="Zones text" /> */}
              <h2>opportunity</h2>
              <h1>ZONES</h1>

              <p>{page && page.get('subhead')}</p>
              {/* <Searchbar /> */}
            </div>
          </Content>
        </Hero>
        <Cards getContentSectionBySlug={getContentSectionBySlug} />
        <DiscoverSection getContentSectionBySlug={getContentSectionBySlug} />

        <MapSection />

        <Divider>
          <img src="./assets/opportunity-zone-sm.svg" alt="Opportunity Zone Small" />
          <img src="./assets/resource-news.svg" width="737" height="111" alt="Divider" />
        </Divider>

        <DarkContent>
          <ScrollableAnchor id="resources">
            <h1 dangerouslySetInnerHTML={{ __html: resources && resources.get('title') }} />
          </ScrollableAnchor>

          <p dangerouslySetInnerHTML={{ __html: resources && resources.get('content') }} />

          {links &&
            links
              .groupBy((item) => item.get('category'))
              .toList()
              .reverse()
              .map((lxs, _) =>
                lxs.entrySeq().map((link) => {
                  const l = link[1];
                  return (
                    l.get('category') === 'official_resources' && (
                      <ResourceLink key={l.get('id')} href={l.get('link')} rel="noopener noreferrer" target="_blank">
                        <h3>{l.get('title')}</h3>
                        <p>{l.get('additionalText')}</p>

                        <div>&rarr;</div>
                      </ResourceLink>
                    )
                  );
                }),
              )}
        </DarkContent>

        <Card>
          <CardItem>
            <img src="./assets/twitter-icon.svg" alt="Twitter Icon" height="50" width="50" />
            <h3>From Twitter</h3>
            <TwitterTimelineEmbed sourceType="profile" screenName="ozfyi" options={{ height: 1200 }} />
          </CardItem>

          <CardItem>
            <img src="./assets/in-the-news-icon.svg" alt="In The News Icon" height="50" width="50" />
            <h3 id="news">In the News</h3>
            {links &&
              links
                .groupBy((item) => item.get('category'))
                .toList()
                .reverse()
                .map((lxs, _) =>
                  lxs.entrySeq().map((link) => {
                    const l = link[1];
                    return (
                      l.get('category') === 'recent_news' && (
                        <ResourceLinkHorizontal key={l.get('id')} href={l.get('link')} rel="noopener noreferrer" target="_blank">
                          <div className="inner">
                            {l.get('title')}
                            <span>{l.get('additionalText')}</span>
                            <div>Read More &rarr;</div>
                          </div>
                        </ResourceLinkHorizontal>
                      )
                    );
                  }),
                )}
          </CardItem>
        </Card>

        <Divider>
          <img src="./assets/faqs-text.png" style={{ top: '110px' }} alt="FAQ icon" />
        </Divider>

        <DarkContent>
          <ScrollableAnchor id="faqs">
            <h1>Browse Help Topics</h1>
          </ScrollableAnchor>

          <p>
            Below you&rsquo;ll find the answers to the questions we get asked the most about the in&rsquo;s and out&rsquo;s of investing in opportunity zones
          </p>

          <div>
            {faqs &&
              faqs.entrySeq().map((faq) => {
                const f = faq[1];

                /* eslint-disable react/no-danger */
                return (
                  <Collapsible key={f.get('id')} trigger={<div dangerouslySetInnerHTML={{ __html: f.get('title') }} />}>
                    <div dangerouslySetInnerHTML={{ __html: f.get('content') }} />
                  </Collapsible>
                );
                /* eslint-enable react/no-danger */
              })}
          </div>
        </DarkContent>
      </HomeStyles>
    );
  }
}

HomeView.propTypes = propTypes;
HomeView.defaultProps = defaultProps;

export default HomeView;
