import React from 'react';
import PropTypes from 'prop-types';
import FooterStyles, { FooterLink } from './footer_styles';
import ContactForm from '../../../hub_spot_form/components/contact_form';

const propTypes = {
  getContentSectionBySlug: PropTypes.func.isRequired,
};

const defaultProps = {};

const FooterView = ({ getContentSectionBySlug }) => {
  const footer = getContentSectionBySlug('footer');
  return (
    <FooterStyles>
      <div className="footer-content">
        {/* Replace these with actual text when typekit is available */}
        <div className="col-sm-4">
          <h3 dangerouslySetInnerHTML={{ __html: footer && footer.get('title') }} />
          <p dangerouslySetInnerHTML={{ __html: footer && footer.get('content') }} />
        </div>
        <div className="col-sm-4">
          <ContactForm />
        </div>
        <div className="col-sm-4">
          <FooterLink>&copy; 2019 Opportunity Zones FYI</FooterLink>
        </div>
      </div>
    </FooterStyles>
  );
};

FooterView.propTypes = propTypes;
FooterView.defaultProps = defaultProps;

export default FooterView;
