import React from 'react';
import { Link } from 'react-router-dom';
import { LogoStyles } from './logo_styles';

const LogoView = () => (
  <LogoStyles>
    <Link to="/">
      <span className="strong">OZ</span>
      FYI
    </Link>
  </LogoStyles>
);

export default LogoView;
