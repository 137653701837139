const theme = {
  color: {
    primary: {
      default: '#354860',
      light: '#6b7c93',
    },
    secondary: {
      default: '#ffde00',
    },
    utility: {
      action: '#f7981c',
      actionHover: '#f7981c',
      disabled: '#CCCCCC',
      error: '#EE0000',
    },
    gray: {
      lighter: '#f5f5f5',
      light: '#ddd',
      default: '#c4c4c4',
      dark: '#999999',
      darker: '#444444',
    },
    whiteOffset: '#f6f6f6',
    white: '#ffffff',
    black: '#000000',
    grayLighter: '#CCCCCC',
    text: {},
  },
  font: {
    sans: '"Inter UI", "Source Sans Pro", sans-serif',
    serif: '"Baskerville", "Source Serif Pro", serif',
    mono: '"Source Code Pro", monospace',
  },
  text: {
    fontSize: 18,
    lineHeight: 24,
  },
  button: {
    borderRadius: 3,
  },
  screen: {},
  section: {},
  padding: {
    small: 5,
    base: 10,
    large: 20,
  },
};

theme.color.text.dark = theme.color.primary.default;
theme.color.text.darker = theme.color.black;
theme.color.text.default = theme.color.primary.light;
theme.color.text.light = theme.color.whiteOffset;

export default theme;
