// Widths
const width = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '800px',
  laptop: '1024px',
  laptopM: '1280px',
  laptopL: '1440px',
  desktop: '2560px',
};

// Variables for use in '@media #{ $device-size }'
const device = {
  mobileS: `(max-width: ${width.mobileS})`,
  mobileM: `(max-width: ${width.mobileM})`,
  mobileL: `(max-width: ${width.mobileL})`,
  tablet: `(max-width: ${width.tablet})`,
  laptop: `(max-width: ${width.laptop})`,
  laptopM: `(max-width: ${width.laptopM})`,
  laptopL: `(max-width: ${width.laptopL})`,
  desktop: `(max-width: ${width.desktop})`,

  onlyMobileS: `(min-width: ${width.mobileS}) and (max-width: ${width.mobileM})`,
  onlyMobileM: `(min-width: ${width.mobileM}) and (max-width: ${width.mobileL})`,
  onlyMobileL: `(min-width: ${width.mobileL}) and (max-width: ${width.tablet})`,
  onlyTablet: `(min-width: ${width.tablet}) and (max-width: ${width.laptop})`,
  onlyLaptop: `(min-width: ${width.laptop}) and (max-width: ${width.laptopM})`,
  onlyLaptopM: `(min-width: ${width.laptopM}) and (max-width: ${width.laptopL})`,
  onlyLaptopL: `(min-width: ${width.laptopL}) and (max-width: ${width.desktop})`,

  laptopUp: `(min-width: calc(${width.laptop} + 1px))`,
  desktopUp: `(min-width: calc(${width.laptopL} + 1px))`,
};

export default device;
