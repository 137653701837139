import styled from 'styled-components';
import theme from '../../theme';
import device from '../../device';

const TextSubheading = styled.div`
  color: ${theme.color.text.dark};
  font-family: ${theme.font.sans};
  line-height: 1.3;
  margin-top: 0;
  text-transform: uppercase;

  ${({ color }) => {
    switch (color) {
      case 'darker':
        return `
          color: ${theme.color.text.darker};
        `;
      case 'dark':
        return `
          color: ${theme.color.text.dark};
        `;
      case 'default':
        return `
          color: ${theme.color.text.default};
        `;
      case 'light':
        return `
          color: ${theme.color.light.default};
        `;
      default:
        return `
          color: ${color};
        `;
    }
  }};

  ${({ size }) => {
    switch (size) {
      case 'large':
        return `
          font-size: 2vw;

          @media ${device.tablet} {
            font-size: 16px;
          }
        `;
      case 'medium':
        return `
          font-size: 1.2vw;

          @media ${device.tablet} {
            font-size: 16px;
          }
        `;
      case 'small':
        return `
          font-size: 1vw;
          text-transform: uppercase;

          @media ${device.tablet} {
            font-size: 16px;
          }
        `;
      default:
        return `
          font-size: 1rem;
        `;
    }
  }};

  ${({ weight }) => {
    switch (weight) {
      case 'bold':
        return `
          font-weight: bold;
        `;
      default:
      case 'normal':
        return `
          font-weight: normal;
        `;
      case 'light':
        return `
          font-weight: 300;
        `;
    }
  }};

`;

export default TextSubheading;
