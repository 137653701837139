import React from 'react';
import { Link } from 'react-router-dom';
import NavbarStyles, { NavbarLink } from './navbar_styles';

const propTypes = {};

const defaultProps = {};

const NavbarView = () => (
  <NavbarStyles>
    <NavbarLink>
      <Link to="/">Home</Link>
    </NavbarLink>
    <NavbarLink>
      <Link to="/funds">Funds</Link>
    </NavbarLink>
    <NavbarLink>
      <Link to="/resources">Resources</Link>
    </NavbarLink>

    <NavbarLink>
      <Link to="/faqs">FAQs</Link>
    </NavbarLink>

    <NavbarLink>
      <Link to="/news">News</Link>
    </NavbarLink>
    {/* <NavbarLinkHighlight>
      {
        signedIn ? (
          <Link to="/signout">Sign Out</Link>
        ) : (
          <Link to="/signup">Sign Up</Link>
        )
      }
    </NavbarLinkHighlight> */}
  </NavbarStyles>
);

NavbarView.propTypes = propTypes;
NavbarView.defaultProps = defaultProps;

export default NavbarView;
