import { reducer as FormReducer } from 'redux-form/immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { combineImmutableReducers } from './util';
import SessionReducer, * as SessionSelect from './session/reducer';
import OzfyiReducer, * as OzfyiSelectors from './ozfyi/reducer';
import GlobalSettingsReducer, * as GlobalSettingsSelectors from './global_settings/reducer';
import HubSpotFormReducer, { getContactFormStatus } from './hub_spot_form/reducer';
import { ContentReducer, ContentSelectors } from './vendor/flamelink';

const Reducer = (history) =>
  combineImmutableReducers({
    router: connectRouter(history),
    content: ContentReducer,
    form: FormReducer,
    session: SessionReducer,
    Ozfyi: OzfyiReducer,
    globalSettings: GlobalSettingsReducer,
    hubSpotForm: HubSpotFormReducer,
  });

export default Reducer;

// Flamelink Content
export const getPages = (state) => ContentSelectors.getPages(state.get('content'));
export const getPageByTitle = (state, title) => ContentSelectors.getPageByTitle(state.get('content'), title);
export const getSnippets = (state) => ContentSelectors.getSnippets(state.get('content'));
export const getSnippetByTitle = (state, title) => ContentSelectors.getSnippetByTitle(state.get('content'), title);
export const getContentSections = (state) => ContentSelectors.getContentSections(state.get('content'));
export const getContentSectionBySlug = (state, slug) => ContentSelectors.getContentSectionBySlug(state.get('content'), slug);

// HubSpot Selectors
export const getHubSpotContactFormStatus = (state) => getContactFormStatus(state.get('hubSpotForm'));

// Ozfyi Selectors
const LOCALE = 'en-US';
export const getFaqs = (state) => state.getIn(['content', 'faq', LOCALE]);
export const getLinks = (state) => state.getIn(['content', 'links', LOCALE]);
export const getFund = (state, id) => OzfyiSelectors.getFund(state.get('Ozfyi'), id);
export const getAllFunds = (state) => OzfyiSelectors.getAllFunds(state.get('Ozfyi'));
export const getFilter = (state) => OzfyiSelectors.getFilter(state.get('Ozfyi'));
export const getFilteredFunds = (state) => OzfyiSelectors.getFilteredFunds(state.get('Ozfyi'));
export const getGlobalSetting = (state, key, notSetValue = undefined) => GlobalSettingsSelectors.getSetting(state.get('globalSettings'), key, notSetValue);
export const getFlashList = (state) => SessionSelect.getFlashList(state.get('session'));
export const getCurrentUser = (state) => SessionSelect.getCurrentUser(state.get('session'));
export const getTreatment = (state, experiment, defaultTreatment) => SessionSelect.getTreatment(state.get('session'), experiment, defaultTreatment);
