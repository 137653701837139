import styled from 'styled-components';
import theme from '../../theme';

const Text = styled.div`
  color: ${theme.color.text.dark};
  font-family: ${theme.font.sans};
  vertical-align: baseline;

  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `};

  ${({ color }) => {
    switch (color) {
      case 'darker':
        return `
          color: ${theme.color.text.darker};
        `;
      case 'dark':
        return `
          color: ${theme.color.text.dark};
        `;
      case 'default':
        return `
          color: ${theme.color.text.default};
        `;
      case 'light':
        return `
          color: ${theme.color.text.light};
        `;
      default:
        return `
          color: ${color};
        `;
    }
  }};

  ${({ size }) => {
    switch (size) {
      case 'large':
        return `
          font-size: 2vw;
        `;
      case 'medium':
        return `
          font-size: 1.45vw;
        `;
      case 'small':
        return `
          font-size: 1.1vw;
        `;
      case 'xsmall':
        return `
          font-size: 0.9vw;
          line-height: 1.4;
        `;
      default:
        return `
          font-size: 16px;
        `;
    }
  }};

  ${({ margin }) => {
    switch (margin) {
      case 'top':
        return `
          margin-top: 1em;
          margin-bottom: 0;
        `;
      case 'bottom':
        return `
          margin-top: 0;
          margin-bottom: 1em;
        `;
      default:
      case 'none':
        return `
          margin: 0;
        `;
    }
  }};

  ${({ weight }) => {
    switch (weight) {
      case 'bold':
        return `
          font-weight: bold;
        `;
      default:
      case 'normal':
        return `
          font-weight: normal;
        `;
      case 'light':
        return `
          font-weight: light;
        `;
    }
  }};

  p {
    margin-bottom: 1em;
  }

`;

export default Text;
