import { Field, reduxForm } from 'redux-form/immutable';
import { TextField, Select, DatePicker } from 'redux-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createTextMask } from 'redux-form-input-masks';

import FormSelect from './form_select';
// import theme from '../theme';

const FieldTheme = createMuiTheme({
  palette: {
    text: '#fff',
    primary: {
      light: '#FFDE00',
      main: '#FFDE00',
      dark: '#FFDE00',
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid rgba(255,255,255,.2)',
        },
      },
    },
  },
});

export { Field, reduxForm, TextField, Select, MenuItem, InputLabel, FormControl, FormSelect, FieldTheme, MuiThemeProvider, DatePicker, createTextMask };
