import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import FundView from './fund_view';
import { getFund } from '../../../reducer';
import { fetchFunds } from '../../actions';

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;

  return {
    fund: getFund(state, id),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFunds: dispatch(fetchFunds()),
});

const FundContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FundView);

export default FundContainer;
