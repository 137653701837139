import { normalize } from 'normalizr';
import * as schema from './schema';
import * as RealBackend from './real_backend';
import * as MockBackend from './mock_backend';

let SelectedBackend;

if (process.env.REACT_APP_API_ENDPOINT === undefined) {
  SelectedBackend = MockBackend;
} else {
  SelectedBackend = RealBackend;
}

const normalizeFunds = (response) => normalize(response.data, [schema.fund]);

export const signIn = (email, password) => SelectedBackend.signIn(email, password);
export const finishSignIn = () => SelectedBackend.finishSignIn();
export const register = (info) => SelectedBackend.register(info);
export const confirmEmail = (token) => SelectedBackend.confirmEmail(token);
export const updateCurrentUser = (token, props) => SelectedBackend.updateCurrentUser(token, props);

export const forgotPassword = (email) => SelectedBackend.forgotPassword(email);
export const resetPassword = (token, password) => SelectedBackend.resetPassword(token, password);

export const listFunds = (user, params) => SelectedBackend.listFunds(user, params).then((rez) => normalizeFunds(rez));

export const { OAUTH } = SelectedBackend;
