import styled from 'styled-components';

const ContactFormStyles = styled.div`
  .btn {
    margin-top: 20px;
    background: #fff;
    border: none;
    border-radius: 30px;
    color: #354860;
    font-size: 1em;
    padding: 10px 20px;
    /* float: right; */
    cursor: pointer;
    &:hover {
      background: #ffde00;
    }
  }
  .field {
    margin-top: 10px;
  }
  h2 {
    margin-bottom: 10px;
  }
  .status {
    margin-bottom: 20px;
  }
`;

export default ContactFormStyles;
