import styled from 'styled-components';

const FooterStyles = styled.div`
  bottom: 0;
  color: #fff;
  min-height: 416px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 9999;

  &::after {
    content: '';
    background: url(/assets/main_map_graphic.png) center center no-repeat;
    background-position: 0 0;
    background-color: #354860;
    background-size: cover;
    /* opacity: 0.5; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  .footer-content {
    text-align: left;
    padding-top: 120px;
    padding-left: 90px;

    @media only screen and (max-width: 800px) {
      padding: 20px;
      .col-sm-4 {
        padding: 40px 0;
        float: none;
      }
    }
  }

  h3 {
    font-size: 20px;
    color: #fff;
  }

  p {
    font-size: 18px;
    color: #ffde00;
  }

  h3,
  p {
    font-weight: 400;
  }

  .input-group {
    margin-top: 45px;
    width: 450px;
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.3);
    border: 0 solid transparent;
    border-radius: 100px;
    padding: 5px;
    display: table;

    .input,
    .input-addon {
      display: table-cell;
    }

    .input {
      position: relative;
      top: -3px;
      height: 20px;
      width: 425px;
      background: transparent;
      font-size: 18px;
      border: none;
      padding-left: 20px;

      &:focus {
        outline-width: 0;
      }

      &::placeholder {
        color: #000;
      }
    }

    .input-addon {
      background-color: #354860;
      border-left: none;
      border-radius: 100px;
      border-right: none;
      font-size: 25px;
      padding: 0.15em 1em;
      text-transform: uppercase;
      width: 25px;

      &--start {
        border-left: none;
      }
      &--end {
        border-right: none;
      }
    }

    .input-addon + .input {
      border: none;
    }
  }

  .footer-links {
    position: absolute;
    bottom: 45px;
    right: 25px;
  }
`;

export const FooterLink = styled.div`
  display: inline;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 10px 15px;
  font-size: 11px;
  font-family: 'Helvetica';
  text-decoration: none;
  a {
    text-decoration: none;
    color: #fff;
  }
`;

export const FooterLinkHighlight = styled(FooterLink)`
  background-color: #f7981c;
  border-radius: 100px;
`;

export default FooterStyles;
