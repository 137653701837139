import styled from 'styled-components';
// import theme from '../theme';

const NavbarStyles = styled.div`
  position: absolute;
  top: 40px;
  right: 20px;
  color: #fff;
  z-index: 9999;

  @media only screen and (max-width: 800px) {
    display: block;
    width: 100%;
    position: unset;
    background: #000;
    padding: 20px;
    text-align: center;
  }
`;

export const NavbarLink = styled.div`
  display: inline;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-family: 'Helvetica';
  text-decoration: none;

  a {
    color: #fff;
    display: inline-block;
    padding: 14px 15px;
    text-decoration: none;
  }
`;

export const NavbarLinkHighlight = styled(NavbarLink)`
  background-color: #f7981c;
  border-radius: 100px;
`;

export default NavbarStyles;
