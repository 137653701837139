import styled from 'styled-components';
import theme from '../../../common/theme';
import device from '../../../common/device';

const Hero = styled.section`
  padding: 80px 1em;
  color: #fff;
  text-align: center;
  position: relative;

  &:after {
    content: '';
    background: url(/assets/main_map_graphic.png) center center no-repeat;
    background-position: 0 0;
    background-color: ${theme.color.primary.default};
    background-size: cover;
    /* opacity: 0.5; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  h1 {
    font-family: 'Georgia';
    font-size: 5em;
    color: ${theme.color.text.light};
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
    letter-spacing: 0.1em;
    margin: 0;
    line-height: 1em;
    text-transform: uppercase;
  }

  h2 {
    font-family: 'Helvetica';
    color: ${theme.color.secondary.default};
    font-weight: normal;
    font-size: 2em;
    letter-spacing: 0.5em;
    margin: 0;
  }

  p {
    font-size: 18px;
    font-weight: normal;
    margin: 30px auto;
    opacity: 0.75;
    width: 90%;

    @media ${device.tablet} {
      font-size: 14px;
    }
  }

  ${({ page }) => {
    switch (page) {
      case 'home':
        return `
          h1 {
            font-size: 9em;
            @media ${device.tablet} {
              font-size: 4em;
            }
          }
          h2 {
            font-size: 3.5em;
            @media ${device.tablet} {
              font-size: 1.7em;
            }
          }
        `;
      default:
      case 'default':
        return `
          h1 {
            font-size: 5em;
            @media ${device.tablet} {
              font-size: 2.5em;
            }
          }
          h2 {
            font-size: 2em;
            @media ${device.tablet} {
              font-size: 1.5em;
            }
          }
        `;
    }
  }};
  .HeroInner {
    margin: 0 auto;
    max-width: 42rem;
  }
`;

export default Hero;
