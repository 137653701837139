import styled from 'styled-components';
import theme from '../../../common/theme';

const FundSearchStyles = styled.div`
  position: relative;

  input {
    border: 0;
    line-height: 2rem;

    &:focus {
      outline: none;
    }
  }

  input[type='text'] {
    border: 1px solid ${theme.color.gray.light};
    border-radius: 2rem;
    flex-grow: 1;
    font-family: ${theme.font.sans};
    font-size: 18px;
    padding: 0.75rem 0.5rem 0.75rem 1.5rem;
    width: 100%;

    &::placeholder {
      color: ${theme.color.gray.dark};
    }

    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0.15rem 0.25rem ${theme.color.gray.darker};
    }
  }

  .button {
    background-color: ${theme.color.utility.action};
    border-radius: 1.5rem;
    color: ${theme.color.white};
    cursor: pointer;
    height: 2.65rem;
    font-size: 16px;
    padding: 0.7rem 1.5rem;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;

    &:before {
      position: relative;
      content: url('/assets/icon-arrow.svg');
      width: 20px;
    }

    &:hover {
      background-color: ${theme.color.utility.actionHover};
    }

    input[type='submit'] {
      background-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
`;

export default FundSearchStyles;
