import { Set } from 'immutable';
import { createSelector } from 'reselect';
import { combineImmutableReducers } from '../../util';
import FundReducer from './fund_reducer';
import FilterReducer, * as FilterSelectors from './filter_reducer';

const REDUCERS = {
  fund: FundReducer,
  filter: FilterReducer,
};

const Reducer = combineImmutableReducers(REDUCERS);

export default Reducer;

// Selectors
// Note: these functions operate on state at the level of the Ozfyi, not the RootReducer.
export const getAllFunds = (state) => FundReducer.selectors.getList(state.get('fund'));
export const getFund = (state, id) => FundReducer.selectors.getOne(state.get('fund'), id);
export const getFilter = (state) => FilterSelectors.getFilter(state.get('filter'));

export const getManagerFunds = createSelector(
  getAllFunds,
  (funds) => funds.filter((fund) => fund.get('manager') !== null),
);

const FUND_FILTER_KEYS = new Set(['name', 'manager', 'geo_focus', 'investment_focus']);

// Filter like google (every word independently matched)
// eslint-disable-next-line no-unused-vars
const keywordFilter = (keywords) => {
  if (!keywords || keywords === '') {
    return () => true;
  }
  const keywordSet = new Set(keywords.split()).map((word) => word.toLowerCase());
  return (fund) =>
    fund &&
    keywordSet.every((keyword) =>
      FUND_FILTER_KEYS.some(
        (k) =>
          fund.get(k) &&
          fund
            .get(k)
            .toLowerCase()
            .includes(keyword),
      ),
    );
};

// Filter for explicit sequence (including whitespace)
const searchFilter = (searchTerm) => {
  if (!searchTerm || searchTerm === '') {
    return () => true;
  }
  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  return (fund) => fund && FUND_FILTER_KEYS.map((k) => fund.get(k)).some((x) => x && x.toLowerCase().includes(lowerCaseSearchTerm));
};

export const getFilteredFunds = createSelector(
  getManagerFunds,
  getFilter,
  (funds, keywords) => funds.filter(searchFilter(keywords)),
);
