import { Record } from 'immutable';

const DEFAULT_ARGS = {
  name: null,
  manager: null,
  size: 0,
  geo_focus: null,
  investment_focus: null,
  additional_details: null,
  org_details: null,
  contact_name: null,
  contact_email: null,
  contact_phone: null,
};

class FundModel extends Record(DEFAULT_ARGS) {
  static _name = 'Fund::FundModel';
}

export default FundModel;
