import React from 'react';
import { Switch, Route } from 'react-router';
import Navbar from './header/navbar';
import Footer from './footer';
import ErrorBoundary from '../../util/error_boundary';
import Home from './home';
import Directory from './directory';
import Fund from './fund';
import SignIn from './sign_in';
import Dashboard from './dashboard';
import Profile from './profile';
import SignOut from './sign_out';

const RootView = () => (
  <div>
    <Navbar />
    <ErrorBoundary>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/resources" component={Home} />
        <Route path="/faqs" component={Home} />
        <Route path="/news" component={Home} />
        <Route path="/funds/:id" component={Fund} />
        <Route path="/funds" component={Directory} />
        <Route path="/signin" component={SignIn} />
        <Route path="/signout" component={SignOut} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/profile" component={Profile} />
      </Switch>
    </ErrorBoundary>
    <Footer />
  </div>
);

export default RootView;
