import styled from 'styled-components';
import theme from '../../../common/theme';
import device from '../../../common/device';

const HomeStyles = styled.div`
  overflow: hidden;
  @media only screen and (max-width: 800px) {
    img {
      max-width: 90% !important;
    }
  }
`;

export const Hero = styled.div`
  .input-group {
    width: 450px;
    background-color: #fff;
    border: 0 solid transparent;
    border-radius: 100px;
    padding: 5px;
    display: table;

    .input,
    .input-addon {
      display: table-cell;
    }

    .input {
      position: relative;
      top: -3px;
      height: 20px;
      width: 425px;
      background: transparent;
      font-size: 18px;
      border: none;
      padding-left: 20px;

      &:focus {
        outline-width: 0;
      }

      &::placeholder {
        color: #bbbbbb;
      }
    }

    .input-addon {
      border-left: none;
      border-right: none;
      border-radius: 100px;
      background-color: #f7981c;
      font-size: 25px;
      padding: 0.15em 1em;
      width: 25px;

      &--start {
        border-left: none;
      }
      &--end {
        border-right: none;
      }
    }

    .input-addon + .input {
      border: none;
    }
  }
`;

export const Content = styled.div`
  padding: 60px 20px;
  width: 100%;

  img {
    &:first-of-type {
      width: 572px;
      height: 70px;
    }

    &:last-of-type {
      margin-top: 10px;
      margin-bottom: 35px;
      width: 570px;
      height: 91px;
    }
  }

  h1,
  h2,
  h3,
  p,
  ul,
  ol {
    margin-top: 20px;
  }

  ol,
  ul {
    padding-left: 40px;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
    ul {
      list-style: circle;
    }
  }

  hr {
    background: none;
    border-bottom: solid 1px #eee;
    border: none;
    margin: 40px 0;
  }
`;

export const Card = styled.div`
  border-bottom: solid 1px #b8c0c8;
  display: flex;
  flex-direction: row;
  min-height: 350px;

  @media only screen and (max-width: 800px) {
    display: block;
  }
`;

export const CardItem = styled.div`
  border-right: solid 1px #b8c0c8;
  padding: 80px;
  width: 50%;

  @media only screen and (max-width: 800px) {
    padding: 40px;
    width: 100%;
  }

  h3 {
    margin-bottom: 20px;
  }

  .inner {
    color: #6b7c93;
    font-size: 13px;
    padding-bottom: 10px;
    padding-top: 10px;
    word-wrap: break-word;
  }

  a {
    color: #f7981c;
    font-size: 13px;
    text-decoration: none;
  }
`;

export const Discover = styled.div`
  background-color: ${theme.color.whiteOffset};
  margin: 0 auto;
  padding: 120px 20px;

  @media ${device.tablet} {
    padding: 60px 20px;
  }
`;

export const DiscoverInner = styled.div`
  margin: 0 auto;
  max-width: 55em;
  width: 100%;
`;

export const Selectable = styled.div`
  display: flex;
  flex-direction: row;
  /* height: 225px; */
  margin: 65px auto 0;
  max-width: 960px;
  width: 100%;

  @media only screen and (max-width: 800px) {
    display: block;
  }
`;

export const SelectableItem = styled.div`
  background-color: #354860;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
  width: 33%;
  display: flex;

  @media only screen and (max-width: 800px) {
    width: calc(100% - 40px);
    display: block;
    margin-bottom: 20px;
  }

  &:hover {
    background-color: #6b7c93;
  }

  h1 {
    color: #ffde00;
    font-size: 26px;
    font-weight: 200;
    text-transform: uppercase;
    margin: 0px;
  }

  p {
    color: #d8d8d8;
  }

  a {
    align-self: center;
    display: block;
    padding: 30px;
    width: 100%;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const MapContent = styled.div`
  margin: 0 auto;
  max-width: 58rem;
  padding: 60px 60px;
  text-align: center;
  width: 100%;

  @media only screen and (max-width: 800px) {
    padding: 20px 40px;
  }

  h1 {
    font-size: 26px;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    color: #354860;
    margin-bottom: 90px;
  }

  iframe {
    margin-bottom: 45px;
  }
`;

export const Divider = styled.div`
  position: relative;
  text-align: center;
  height: 310px;

  &::after {
    content: '';
    background: url(/assets/main_map_graphic.png) center center no-repeat;
    background-position: 0 0;
    background-color: #354860;
    background-size: cover;
    /* opacity: 0.5; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  img {
    margin: 0 auto;
    position: relative;
    top: 100px;
    display: block;
  }
`;

export const DarkContent = styled(Content)`
  max-width: 100%;
  background-color: #f6f6f6;
  margin: 0 auto;
  padding-top: 75px;
  padding-bottom: 75px;
  border: solid 1px #b8c0c8;

  h1 {
    margin-top: 0;
  }

  p {
    color: #6b7c93;
  }

  h1,
  p {
    text-align: center;
  }

  .Collapsible__contentInner {
    padding: 10px;
    border: 1px solid $lightGrey;
    border-top: 0;

    p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .Collapsible {
    margin: 0 auto;
    padding: 15px 15px 15px 45px;
    width: 75%;
    @media only screen and (max-width: 800px) {
      width: unset;
    }

    h3 {
      font-weight: 200;
      margin-top: 0;
      text-transform: capitalize;
      cursor: pointer;
      &:hover {
        color: #6b7c93;
      }
    }

    h3,
    p {
      text-align: left;
    }
  }

  .Collapsible__trigger {
    background-color: #fff;
    border: 1px solid white;
    color: #000;
    color: $grey;
    display: block;
    font-weight: 200;
    padding: 10px;
    position: relative;
    text-decoration: none;

    &:after {
      background-color: #000;
      color: #fff;
      content: 'Q';
      display: block;
      left: -44px;
      padding: 7px 15px 7px 15px;
      position: absolute;
      top: 0;
      transition: transform 500ms;
    }

    &.is-open {
      &:after {
        background-color: #fff;
        color: #000;
        border: solid 1px #f7981c;
        content: 'A';
        display: block;
        left: -44px;
        padding: 7px 15px 7px 15px;
        position: absolute;
        top: 0;
        transition: transform 500ms;
        transform: rotateZ(360deg);
      }
    }

    &.is-disabled {
      opacity: 0.5;
      background-color: grey;
    }
  }

  .Collapsible__contentOuter {
    background-color: #fff;
  }
`;

export const ResourceLink = styled.a`
  display: block;
  text-decoration: none;
  background-color: #fff;
  border-left: solid 8px #000;
  height: 95px;
  margin-bottom: 15px;
  margin: 0 auto;
  padding-left: 45px;
  position: relative;
  width: 900px;

  @media only screen and (max-width: 800px) {
    width: auto;
    height: auto;
    padding: 0px 20px 10px 20px;
    margin: 0 0 10px 0;
  }

  &:hover {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }

  h3 {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    padding-top: 15px;
    text-transform: uppercase;
  }

  p {
    color: #6b7c93;
    font-size: 14px;
    left: 25px;
    margin-top: 0;
    position: relative;
    text-align: left;
  }

  div {
    bottom: 25px;
    color: #f7981c;
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    right: 45px;
    text-decoration: none;
    @media only screen and (max-width: 800px) {
      position: unset;
    }
  }
`;

export const ResourceLinkHorizontal = styled(ResourceLink)`
  border-left: none;
  border-top: solid 8px #000;
  margin-bottom: 45px;
  margin-left: 0;
  min-height: 100px;
  padding-bottom: 20px;
  padding-left: 0;
  width: 100%;
  @media only screen and (max-width: 800px) {
    padding: 0px;
  }

  &:hover {
    box-shadow: none;
    border-color: #6b7c93;
  }

  p {
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
    min-width: 350px;
    left: 0px;

    @media only screen and (max-width: 800px) {
      min-width: 0px;
    }

    span {
      color: #6b7c93;
      display: block;
      margin-top: 10px;
      text-transform: capitalize;
    }
  }

  div {
    font-size: 12px;
    left: 0;
    position: relative;
    top: 8px;
  }
`;

export const Footer = styled.div`
  color: #999;
  font-size: 0.8em;
  margin-top: 100px;
`;

export default HomeStyles;
