import styled from 'styled-components';
import theme from '../../../../common/theme';
import device from '../../../../common/device';

export const LogoStyles = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 2;

  @media ${device.tablet} {
    display: none;
  }

  a {
    background-color: ${theme.color.text.darker};
    color: ${theme.color.text.light};
    display: block;
    font-size: 1.5em;
    padding: 10px 40px;
    text-decoration: none;
  }

  .strong {
    font-weight: 700;
  }
`;

export default LogoStyles;
