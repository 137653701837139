import styled from 'styled-components';
import theme from '../../theme';
import device from '../../device';

const TextHeading = styled.h2`
  font-family: ${theme.font.serif};
  color: ${theme.color.text.darker};
  letter-spacing: 0.039em;
  line-height: 1.2;

  ${({ align }) => {
    switch (align) {
      default:
      case 'left':
        return `
          text-align: left;
        `;
      case 'center':
        return `
          text-align: center;
        `;
      case 'right':
        return `
          text-align: right;
        `;
    }
  }};

  ${({ margin }) => {
    switch (margin) {
      case 'top':
        return `
          margin-top: 0.5em;
          margin-bottom: 0;
        `;
      default:
      case 'bottom':
        return `
          margin-top: 0;
          margin-bottom: 0.5em;
        `;
      case 'none':
        return `
          margin: 0;
        `;
    }
  }};

  ${({ size }) => {
    switch (size) {
      default:
      case 'large':
        return `
          font-size: 3vw;

          @media ${device.tablet} {
            font-size: 28px;
          }
        `;
      case 'medium':
        return `
          font-size: 2vw;

          @media ${device.tablet} {
            font-size: 22px;
          }
        `;
      case 'small':
        return `
          font-size: 1.2vw;
          text-transform: uppercase;

          @media ${device.tablet} {
            font-size: 16px;
          }
        `;
    }
  }};

  ${({ weight }) => {
    switch (weight) {
      default:
      case 'bold':
        return `
          font-weight: 600;
        `;
      case 'normal':
        return `
          font-weight: normal;
        `;
      case 'light':
        return `
          font-weight: light;
        `;
    }
  }};

`;

export default TextHeading;
