import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import SignInStyles from './sign_in_styles';

const propTypes = {
  signedIn: PropTypes.bool.isRequired,
};

const SignInView = ({ signedIn }) => {
  if (signedIn) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <SignInStyles>
      <span>blah blah signin form...</span>
    </SignInStyles>
  );
};

SignInView.propTypes = propTypes;

export default SignInView;
