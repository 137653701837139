import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import ReactTable from 'react-table';
import { withRouter } from 'react-router-dom';
import DirectoryStyles, { TableContent, Content, Section } from './directory_styles';
import Logo from '../header/logo';
import Hero from '../hero';
import FundSearch from '../fund_search';

const COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Manager',
    accessor: 'manager',
  },
  {
    Header: 'Size',
    accessor: 'formatted_size',
  },
  {
    Header: 'Geo Focus',
    accessor: 'geo_focus',
  },
  {
    Header: 'Investment focus',
    accessor: 'investment_focus',
  },
];

const propTypes = {
  funds: PropTypes.instanceOf(OrderedSet),
  history: PropTypes.shape({}).isRequired,
  getContentSectionBySlug: PropTypes.func.isRequired,
};

const defaultProps = {
  funds: new OrderedSet(),
};

const DirectoryView = ({ history, funds, getContentSectionBySlug }) => {
  const listing = getContentSectionBySlug('listing');
  return (
    <DirectoryStyles>
      <Logo />

      <Hero>
        <Content>
          <div>
            {/* <img src="./assets/opportunity-zone-text.svg" alt="Opportunity Zone text" />
            <img src="./assets/funds-directory-text.svg" alt="Funds Directory text" /> */}
            <h2>Opportunity Funds</h2>
            <h1>DIRECTORY</h1>
          </div>
        </Content>
      </Hero>

      <Section>
        <Content>
          <h3 dangerouslySetInnerHTML={{ __html: listing && listing.get('title') }} />
          <p dangerouslySetInnerHTML={{ __html: listing && listing.get('content') }} />
        </Content>
      </Section>

      <TableContent>
        <FundSearch/>
        <br />

        <ReactTable
          sortable
          resizable
          defaultPageSize={25}
          getTrProps={(state, rowInfo) => ({
            onClick: () => history.push(`/funds/${rowInfo.original.id}`),
          })}
          className="-striped -highlight"
          data={funds.toJS()}
          columns={COLUMNS}
        />
      </TableContent>

    </DirectoryStyles>
  );
};

DirectoryView.propTypes = propTypes;
DirectoryView.defaultProps = defaultProps;

export default withRouter(DirectoryView);
