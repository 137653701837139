import React from 'react';
import PropTypes from 'prop-types';
import FundStyles, { Hero, Content, Logo, Card, Section, MapContainer, ResourceLinkHorizontal } from './fund_styles';

const propTypes = {
  fund: PropTypes.shape({}),
};

const defaultProps = {
  fund: undefined,
};

const FundView = ({ fund }) => (
  <FundStyles>
    <Logo>
      <b>OZ</b>
      FYI
    </Logo>
    <Hero>
      <Content>
        <div>
          <img src="../assets/opportunity-zone-text.svg" alt="Opportunity Zone text" />
          <img src="../assets/funds-directory-text.svg" alt="Funds Directory text" />
        </div>
      </Content>
    </Hero>

    <Section>
      <Content>
        <h3>{fund && fund.get('name')}</h3>
        {fund && fund.get('location') && <p>{fund.get('location')}</p>}
      </Content>

      <Content>
        <Card>
          <MapContainer />
          <ResourceLinkHorizontal>
            <img src="../assets/smart-money-icon.svg" alt="Smart Money Icon" />
            <div>
              <h3>About This Fund</h3>

              <p>{fund && fund.get('additional_details') ? fund.get('additional_details') : 'N/A'}</p>
            </div>
          </ResourceLinkHorizontal>

          {fund && fund.get('url') && (
            <ResourceLinkHorizontal>
              <p className="url">{fund.get('url')}</p>
            </ResourceLinkHorizontal>
          )}
        </Card>

        <Card>
          <ResourceLinkHorizontal>
            <img src="../assets/fund-circle.svg" alt="Smart Money Icon" />
            <div>
              <h3>Fund Size</h3>
              <p>{fund && fund.get('formatted_size') ? fund && fund.get('formatted_size') : 'N/A'}</p>
            </div>
          </ResourceLinkHorizontal>

          {
            <ResourceLinkHorizontal>
              <img src="../assets/geographic-circle.svg" alt="Geographic Circle" />
              <div>
                <h3>Geographic Focus</h3>
                <p>{fund && fund.get('geo_focus') ? fund && fund.get('geo_focus') : 'N/A'}</p>
              </div>
            </ResourceLinkHorizontal>
          }

          <ResourceLinkHorizontal>
            <img src="../assets/chart-circle.svg" alt="Chart Circle" />
            <div>
              <h3>Investment Focus</h3>
              <p>{fund && fund.get('investment_focus') ? fund && fund.get('investment_focus') : 'N/A'}</p>
            </div>
          </ResourceLinkHorizontal>
        </Card>
      </Content>
    </Section>
  </FundStyles>
);

FundView.propTypes = propTypes;
FundView.defaultProps = defaultProps;

export default FundView;
