import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import DirectoryView from './directory_view';
import { getFilteredFunds, getContentSectionBySlug } from '../../../reducer';
import { fetchFunds } from '../../actions';

const mapStateToProps = (state) => ({
  getContentSectionBySlug: (title) => getContentSectionBySlug(state, title),
  funds: getFilteredFunds(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchFunds: dispatch(fetchFunds()),
});

const DirectoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DirectoryView);

export default DirectoryContainer;
