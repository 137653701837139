import React from 'react';
import PropTypes from 'prop-types';
import ContactFormStyles from './contact_form_styles';
import { Field, reduxForm, TextField, FieldTheme, MuiThemeProvider } from '../../../common/form/form';

const propTypes = {
  status: PropTypes.oneOf([null, 'submitting', 'success', 'failed']),
};

const defaultProps = {
  status: null,
};

const ContactForm = ({
  // eslint-disable-next-line react/prop-types
  handleSubmit,
  status,
}) => (
  <ContactFormStyles>
    <p>Sign up for updates</p>
    {status === 'failed' && <div className="status">Oops... Something went wrong.</div>}
    {status === 'success' ? (
      <div className="status">Thank you for signing up!</div>
    ) : (
      <MuiThemeProvider theme={FieldTheme}>
        <form onSubmit={handleSubmit}>
          {status === 'error' && <div>Sorry, Something went wrong...</div>}
          <div className="row">
            <div className="col-sm-6 field">
              <Field component={TextField} label="First Name" type="text" name="firstname" fullWidth />
            </div>
            <div className="col-sm-6 field">
              <Field component={TextField} label="Last Name" type="text" name="lastname" fullWidth />
            </div>
          </div>
          <div className="xcol-sm-4 field">
            <Field component={TextField} label="Email Address" name="email" type="email" fullWidth required />
          </div>
          <div className="xcol-sm-2">
            <input type="submit" className="btn primary" value="Sign Up" />
          </div>
        </form>
      </MuiThemeProvider>
    )}
  </ContactFormStyles>
);

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

const ContactFormView = reduxForm({ form: 'ContactForm', enableReinitialize: true })(ContactForm);

export default ContactFormView;
