import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import HomeView from './home_view';
import { getFaqs, getLinks, getPageByTitle, getContentSectionBySlug, getAllFunds } from '../../../reducer';
import { fetchFunds } from '../../actions';
import { updateContent } from '../../../vendor/flamelink';

const mapStateToProps = (state) => ({
  funds: getAllFunds(state).toJS(),
  getContentSectionBySlug: (title) => getContentSectionBySlug(state, title),
  page: getPageByTitle(state, 'Home'),
  faqs: getFaqs(state),
  links: getLinks(state),
});

const mapDispatchToProps = {
  fetchFunds,
  updateContent,
};

const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeView);

export default HomeContainer;
