// Firebase App is always required and must be first
import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyCrRsZQWju2Xp0SFhHrE5L0i2ubbjg1e4g',
  authDomain: 'opportunity-zones-fyi.firebaseapp.com',
  databaseURL: 'https://opportunity-zones-fyi.firebaseio.com',
  projectId: 'opportunity-zones-fyi',
  storageBucket: 'opportunity-zones-fyi.appspot.com',
  messagingSenderId: '353594688270',
};

firebase.initializeApp(config);

export const db = firebase.database;

export default firebase;
