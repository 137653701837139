import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import FooterView from './footer_view';
import { getContentSectionBySlug } from '../../../reducer';

const mapStateToProps = (state) => ({
  getContentSectionBySlug: (slug) => getContentSectionBySlug(state, slug),
});

const FooterContainer = connect(
  mapStateToProps,
)(FooterView);

export default FooterContainer;
